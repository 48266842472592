import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/styles";
import QuoteElement, { QuoteProps } from "../quote-element/quote-element";
import { Headline2, Headline3, Paragraph } from "../typography";
import { ImageDataInterface } from "../../graphql/common";
import { PimcoreImage } from "../pimcore-image";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../utilities/animation-helper";

interface ParagraphProps {
  padding?: string;
}

interface WorkingTogetherProps extends ParagraphProps {
  headline?: string;
  subHeadline?: string;
  content?: string;
  image?: ImageDataInterface;
  marginBottom?: string | null;
  quote?: QuoteProps;
}

interface MediaProps {
  marginBottom?: string | null;
}

const WorkingTogetherSection: React.FC<WorkingTogetherProps> = ({
  headline,
  subHeadline,
  content,
  image,
  marginBottom,
  padding,
  quote,
}) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(wrapper.current, [wrapper?.current?.children || null], matchMobile);

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <Grid1 ref={wrapper}>
      <MediaCell1 marginBottom={marginBottom}>
        <Headline2 dangerouslySetInnerHTML={{ __html: headline || "" }} />
      </MediaCell1>
      <MediaCell1_2>
        <Headline3 dangerouslySetInnerHTML={{ __html: subHeadline || "" }} />
      </MediaCell1_2>
      <MediaCell2>
        {quote && <QuoteElement {...quote} />}
        <PimcoreImage image={image} modifier="w-full rounded-lg" withAspectRatio />
      </MediaCell2>
      <MediaCell3>
        <ParagraphWrapper padding={padding}>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: content || "" }} />
        </ParagraphWrapper>
      </MediaCell3>
    </Grid1>
  );
};

export default WorkingTogetherSection;

const Grid1 = styled.div.attrs({
  className: "tablet:grid gap-4 px-5 tablet:grid-cols-12 mb-12 tablet:mb-32",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(2, auto);
  }
`;
const ParagraphWrapper = styled.div.attrs<ParagraphProps>(({ padding }) => ({
  className: `${padding ? padding : "tablet:pr-[4.5rem]"}`,
}))<ParagraphProps>``;
const MediaCell1 = styled.div.attrs<MediaProps>(({ marginBottom }) => ({
  className: `tablet:col-start-2 tablet:col-span-3 tablet:row-start-1 tablet:row-span-1 pr-4 tablet:pr-0 mb-4 ${
    marginBottom ? marginBottom : "tablet:mb-14"
  }`,
}))<MediaProps>``;
const MediaCell1_2 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-3 tablet:row-span-1",
})``;
const MediaCell2 = styled.div.attrs({
  className:
    "tablet:col-start-6 tablet:col-span-6 tablet:row-start-1 tablet:row-span-4 mb-8 tablet:mb-0 tablet:mt-auto relative",
})``;

const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-4 tablet:row-span-1 ",
})``;
