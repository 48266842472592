import React from "react";
import styled from "styled-components";
import SimpleSection from "../../../components/work/simple-section";
import WorkingTogetherSection from "../../../components/work/working-together-section";
import { PageElement } from "../../../graphql/page-common";
import WorkVideoElement from "../work-video-element";
import { getElementImage, getElementText, getElementVideo } from "../../../utilities/pimcore-data-transformer";

interface WorkingSectionProps {
  headline: string;
  intro: Array<PageElement>;
  content: Array<PageElement>;
}

const WorkingSection: React.FC<WorkingSectionProps> = ({ headline, intro, content }) => {
  return (
    <>
      <VideoWrapper>
        <WorkVideoElement
          video={getElementVideo(intro[0])}
          image={getElementImage(intro[0])}
          content={getElementText(intro[1])}
          padding="tablet:pr-24"
        />
      </VideoWrapper>
      <SectionWrapper>
        <WorkingTogetherSection
          headline={headline}
          subHeadline={getElementText(content[0])}
          image={getElementImage(content[1])}
          content={getElementText(content[2])}
          marginBottom="tablet:mb-8"
          padding="tablet:pr-14"
        />

        <SimpleSection
          headline={getElementText(content[3])}
          image={getElementImage(content[4])}
          content={getElementText(content[5])}
          padding="tablet:pr-12"
          left
          marginTop="tablet:mt-8"
        />
        <SimpleSection
          headline={getElementText(content[6])}
          image={getElementImage(content[7])}
          content={getElementText(content[8])}
          quote={{
            message:
              "We focus everything around the customer. Always listening to feedback and continuously improving. With the ultimate goal to delight our customers.",
            name: "Gregor",
            position: "VP Customer Engagement & Performance",
            modifier: "left-4 bottom-4 tablet:bottom-80 tablet:left-[32rem]",
            left: true,
          }}
          padding="tablet:pr-12"
          marginTop="tablet:mt-0"
        />
        <SimpleSection
          headline={getElementText(content[9])}
          image={getElementImage(content[10])}
          content={getElementText(content[11])}
          padding="tablet:pr-16"
          left
        />
      </SectionWrapper>
    </>
  );
};

export default WorkingSection;

const VideoWrapper = styled.div.attrs({ className: "-mt-64 tablet:-mt-72 relative z-20 scrollspy" })``;
const SectionWrapper = styled.div.attrs({ className: "scrollspy" })``;
