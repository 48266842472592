import React from "react";
import styled from "styled-components";
import { PimcoreImage } from "../../../components/pimcore-image";
import { Headline3, Paragraph } from "../../../components/typography";
import ExampleHeaderSection from "../../../components/work/example-header-section";
import SimpleSection from "../../../components/work/simple-section";
import { PageElement } from "../../../graphql/page-common";
import { useMatchMedia } from "../../../hooks/use-match-media";
import { theme } from "../../../styles/styles";
import { onScrollAnimationDesktop } from "../../../utilities/animation-helper";
import { getElementImage, getElementText } from "../../../utilities/pimcore-data-transformer";

interface ExamplesSectionProps {
  headline: string;
  content: Array<PageElement>;
}

const ExamplesSection: React.FC<ExamplesSectionProps> = ({ headline, content }) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(wrapper.current, [wrapper.current?.children || null], matchMobile);

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <SectionWrapper>
      <ExampleHeaderSection
        headline={headline}
        subHeadline={getElementText(content[0])}
        primaryImage={getElementImage(content[1])}
        content={getElementText(content[2])}
        secondaryImage={getElementImage(content[3])}
        marginTop="tablet:mt-16"
        bigger
        prParagraph="6"
      />
      <Grid ref={wrapper}>
        <MediaCell1>
          <Headline3 dangerouslySetInnerHTML={{ __html: getElementText(content[4]) }} />
        </MediaCell1>
        <MediaCell2>
          <PimcoreImage image={getElementImage(content[5])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell2>
        <MediaCell3>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[6]) }} />
        </MediaCell3>
        <MediaCell4>
          <PimcoreImage image={getElementImage(content[7])} modifier="w-full rounded-lg" withAspectRatio />
        </MediaCell4>
        <MediaCell5>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: getElementText(content[8]) }} />
        </MediaCell5>
      </Grid>
      <SimpleSection
        headline={getElementText(content[9])}
        image={getElementImage(content[10])}
        content={getElementText(content[11])}
        imgFull
        left
        padding="tablet:pr-5"
        marginTop="tablet:mt-0"
      />
    </SectionWrapper>
  );
};

export default ExamplesSection;

const SectionWrapper = styled.div.attrs({ className: "scrollspy" })``;

const Grid = styled.div.attrs({
  className: "tablet:grid tablet:grid-cols-12 tablet:gap-4 px-5 mb-12 tablet:mb-36",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(6, auto);
  }
`;
const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-3 tablet:row-start-1 tablet:row-span-1 ",
})``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-7 tablet:col-span-6 tablet:row-start-1 tablet:row-span-4 mb-8 tablet:mb-0",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-2 tablet:col-span-4 tablet:row-start-2 tablet:row-span-2 tablet:pr-14 mb-8 tablet:mb-0",
})``;
const MediaCell4 = styled.div.attrs({
  className:
    "hidden tablet:block tablet:col-start-2 tablet:col-span-5 tablet:row-start-4 tablet:row-span-3 tablet:mt-8",
})``;
const MediaCell5 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-6 tablet:row-span-1 tablet:pr-6",
})``;
