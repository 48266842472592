import { graphql, PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";
import HeaderImage from "../../../components/header-image/header-image";
import { CategoryJobOffers } from "../../../components/job-offer";
import Layout from "../../../components/layout";
import Scrollspy from "../../../components/scrollspy/scrollspy";
import { BenefitsTile, TileNavigation } from "../../../components/tile-navigation";
import MeetTeam from "../../../components/work/meet-team";
import { Routes } from "../../../data/routing";
import { JobOfferListingInterface } from "../../../graphql/jobs";
import { WorkCategoryPageContentInterface } from "../../../graphql/work-category-page";
import ExamplesSection from "../../../sections/work/customer/examples-section";
import WorkingSection from "../../../sections/work/customer/working-section";

interface CustomerEngagementPageDataInterface {
  pimcore: {
    content: WorkCategoryPageContentInterface;
    categoryJobOffers: JobOfferListingInterface;
  };
}

const labels = ["Our work", "Working Together", "Examples of our work", "Meet some of the team", "Latest jobs"];

const CustomerEngagementPage: React.FC<PageProps<CustomerEngagementPageDataInterface>> = ({ data }) => {
  const content = data.pimcore.content;

  return (
    <Layout
      navCurrentItem="work"
      navCurrentSubItem="customer-engagement-and-performance-marketing"
      title={content.metaTitle}
      description={content.metaDescription}
      canonical={content.metaCanonical}
      openGraph={{ title: content.metaOgTitle, description: content.metaOgTitle, image: content.metaOgImage }}
      schemaOrg={content.metaSchema}
    >
      <Header>
        <HeaderImage
          headline={content.heroHeadline}
          subHeadline={content.heroSubheadline}
          image={content.heroBackground}
          mobileImage={content.heroBackgroundMobile}
          btnSrc={Routes.work}
          btnLabel="Back to Work overview"
        />
      </Header>
      <Main>
        <WorkingSection headline={content.workHeadline} intro={content.intro} content={content.work} />
        <ExamplesSection headline={content.examplesHeadline} content={content.examples} />
        <MeetTeam headline={content.teamHeadline} members={content.team} />
        {data.pimcore.categoryJobOffers.edges.length > 0 && (
          <CategoryJobOffers
            headline={content.jobsHeadline}
            description={content.jobsDescription}
            jobOffers={data.pimcore.categoryJobOffers.edges.map((edge) => edge.node)}
          />
        )}
        <TileNavigation top={"team"} bottom={"office"}>
          <BenefitsTile />
        </TileNavigation>
      </Main>
      <Scrollspy labels={labels} gap={data.pimcore.categoryJobOffers.edges.length > 0 ? 6 : 1} />
    </Layout>
  );
};

const Header = styled.header.attrs({ className: "h-screen" })``;
const Main = styled.div.attrs({ className: "x-background" })``;

export const query = graphql`
  query($fullpath: String = "/Pages/Home/Work/Customer Engagement", $filter: String = "{\"category\":\"customer-engagement-and-performance-marketing\"}") {
    pimcore {
      ...workCategoryPageData
      ...categoryJobOffers
    }
  }
`;
export default CustomerEngagementPage;
