import React from "react";
import styled from "styled-components";
import Video from "../../components/media/video";
import { PimcoreImage } from "../../components/pimcore-image";
import { Paragraph } from "../../components/typography";
import { ImageDataInterface, VideoDataInterface } from "../../graphql/common";
import { useMatchMedia } from "../../hooks/use-match-media";
import { theme } from "../../styles/styles";
import { onScrollAnimationDesktop, returnRefArray } from "../../utilities/animation-helper";

interface MediaProps {
  padding?: string;
}

interface WorkVideo extends MediaProps {
  image?: ImageDataInterface;
  video?: VideoDataInterface;
  content: string;
}

const WorkVideoElement: React.FC<WorkVideo> = ({ video, image, content, children, padding }) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    const indexChildrenSection = [0, 1, 2, 3, 4, 5];

    tl.current = onScrollAnimationDesktop(
      wrapper.current,
      returnRefArray(wrapper?.current, indexChildrenSection, [1, 2]),
      matchMobile
    );

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <Wrapper ref={wrapper}>
      <MediaCell1>
        {video && <Video placeholder={image} video={video} imageLoading="eager" />}
        {!video && <PimcoreImage image={image} modifier="w-full rounded-lg" loading="eager" withAspectRatio />}
      </MediaCell1>
      <MediaCell2 padding={padding}>
        <Paragraph as="div" dangerouslySetInnerHTML={{ __html: content }} />
      </MediaCell2>
      {children}
    </Wrapper>
  );
};

export default WorkVideoElement;

const Wrapper = styled.div.attrs({
  className: "tablet:mb-36 tablet:grid tablet:grid-cols-12 gap-4 px-5 relative z-10",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(1, auto);
  }
`;
const MediaCell1 = styled.div.attrs({
  className: "tablet:col-start-1 tablet:col-span-7 tablet:row-start-1 tablet:row-span-1 mb-8 tablet:mb-0",
})``;
const MediaCell2 = styled.div.attrs<MediaProps>(({ padding }) => ({
  className: `tablet:col-start-8 tablet:col-span-5 tablet:row-start-1 tablet:row-span-1 tablet:ml-20 tablet:my-auto mb-14 ${
    padding ? padding : "tablet:pr-24"
  }`,
}))<MediaProps>``;
