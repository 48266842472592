import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/styles";
import QuoteElement, { QuoteProps } from "../quote-element/quote-element";
import { ImageLeftWrapper } from "../images/images";
import Video from "../media/video";
import { Headline3, Paragraph } from "../typography";
import { ImageDataInterface, VideoDataInterface } from "../../graphql/common";
import { PimcoreImage } from "../pimcore-image";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../utilities/animation-helper";

interface SimpleSectionProps {
  headline?: string;
  content?: string;
  image?: ImageDataInterface;
  video?: VideoDataInterface;
  marginTop?: string | null;
  left?: boolean | null;
  padding?: string;
  imgFull?: boolean | null;
  start?: "7" | null;
  quote?: QuoteProps;
}

interface MediaCellProps {
  marginTop?: string | null;
  left?: boolean | null;
  imgFull?: boolean | null;
  start?: "7" | null;
}

interface ParagraphProps {
  padding?: string;
}

const SimpleSection: React.FC<SimpleSectionProps> = ({
  headline,
  content,
  image,
  video,
  quote,
  marginTop,
  left,
  padding,
  imgFull,
  start,
}) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(wrapper.current, [wrapper.current?.children || null], matchMobile);

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <Grid ref={wrapper}>
      <MediaCell1 marginTop={marginTop} left={left}>
        <Headline3 dangerouslySetInnerHTML={{ __html: headline || "" }} />
      </MediaCell1>
      <MediaCell2 left={left} imgFull={imgFull} start={start}>
        {quote && <QuoteElement {...quote} />}
        {left && (
          <ImageLeftWrapper>
            {video && <Video placeholder={image} video={video} />}
            {!video && <PimcoreImage image={image} modifier="w-full rounded-lg" withAspectRatio />}
          </ImageLeftWrapper>
        )}
        {!left && video && <Video placeholder={image} video={video} />}
        {!left && !video && <PimcoreImage image={image} modifier="w-full rounded-lg" withAspectRatio />}
      </MediaCell2>
      <MediaCell3 left={left}>
        <ParagraphWrapper padding={padding}>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: content || "" }} />
        </ParagraphWrapper>
      </MediaCell3>
    </Grid>
  );
};

export default SimpleSection;

const Grid = styled.div.attrs({
  className: "tablet:grid gap-4 px-5 tablet:grid-cols-12 mb-12 tablet:mb-32",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(3, auto);
  }
`;
const ParagraphWrapper = styled.div.attrs<ParagraphProps>(({ padding }) => ({
  className: `${padding ? padding : "tablet:pr-4"}`,
}))<ParagraphProps>``;
const MediaCell1 = styled.div.attrs<MediaCellProps>(({ left, marginTop }) => ({
  className: `

  ${
    left
      ? `tablet:col-start-8 tablet:col-span-4 pr-4 tablet:row-start-1 tablet:row-span-1 ${
          marginTop ? marginTop : "tablet:mt-20"
        } mb-8 tablet:mb-0`
      : `tablet:col-start-2 tablet:col-span-4 pr-4 tablet:row-start-1 tablet:row-span-1 ${
          marginTop ? marginTop : "tablet:mt-16"
        }  mb-8 tablet:mb-0`
  } `,
}))<MediaCellProps>``;
const MediaCell2 = styled.div.attrs<MediaCellProps>(({ left, imgFull, start }) => ({
  className: `relative ${
    left
      ? `${
          imgFull
            ? `tablet:col-start-${start ? start : "1"} tablet:col-span-7`
            : `tablet:col-start-${start ? start : "2"} tablet:col-span-6`
        }  tablet:row-start-1 tablet:row-span-3 mb-8 tablet:mb-0 `
      : `${imgFull ? "tablet:col-span-7" : "tablet:col-span-6"} tablet:col-start-${
          start ? start : "6"
        } tablet:row-start-1 tablet:row-span-3 mb-8 tablet:mb-0`
  } `,
}))<MediaCellProps>``;
const MediaCell3 = styled.div.attrs<MediaCellProps>(({ left }) => ({
  className: `${
    left
      ? "tablet:col-start-8 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1"
      : "tablet:col-start-2 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1"
  } `,
}))<MediaCellProps>``;
