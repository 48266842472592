import React from "react";
import styled from "styled-components";
import { theme } from "../../styles/styles";
import QuoteElement, { QuoteProps } from "../quote-element/quote-element";
import Video from "../media/video";
import { Headline2, Headline3, Paragraph } from "../typography";
import { ImageDataInterface, VideoDataInterface } from "../../graphql/common";
import { PimcoreImage } from "../pimcore-image";
import { useMatchMedia } from "../../hooks/use-match-media";
import { onScrollAnimationDesktop } from "../../utilities/animation-helper";

interface ExampleHeaderProps {
  headline?: string;
  subHeadline?: string;
  content?: string;
  primaryImage?: ImageDataInterface;
  video?: VideoDataInterface;
  secondaryImage?: ImageDataInterface;
  marginTop?: string | null;
  bigger?: boolean | null;
  prParagraph?: string | null;
  quote?: QuoteProps;
}

interface ParagraphProps {
  prParagraph?: string | null;
}

interface MediaCellProps {
  bigger?: boolean | null;
  marginTop?: string | null;
}

const ExampleHeaderSection: React.FC<ExampleHeaderProps> = ({
  headline,
  subHeadline,
  content,
  primaryImage,
  secondaryImage,
  video,
  marginTop,
  bigger,
  prParagraph,
  quote,
}) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  const tl = React.useRef<GSAPTimeline>();
  const matchMobile = useMatchMedia("(max-width: 768px)");

  React.useEffect(() => {
    tl.current = onScrollAnimationDesktop(wrapper.current, [wrapper.current?.children || null], matchMobile);

    return () => {
      tl?.current?.kill();
    };
  }, [matchMobile]);

  return (
    <Grid ref={wrapper}>
      <MediaCell1 marginTop={marginTop}>
        <Headline2 dangerouslySetInnerHTML={{ __html: headline || "" }} />
      </MediaCell1>
      <MediaCell2>
        <Headline3 dangerouslySetInnerHTML={{ __html: subHeadline || "" }} />
      </MediaCell2>
      <MediaCell3>
        <ImgMarginWrapper>
          {video && <Video placeholder={primaryImage} video={video} />}
          {!video && <PimcoreImage image={primaryImage} modifier="w-full rounded-lg" withAspectRatio />}
        </ImgMarginWrapper>
      </MediaCell3>
      <MediaCell4>
        <ParagraphWrapper prParagraph={prParagraph}>
          <Paragraph as="div" dangerouslySetInnerHTML={{ __html: content || "" }} />
        </ParagraphWrapper>
      </MediaCell4>
      <MediaCell5 bigger={bigger}>
        {quote && <QuoteElement {...quote} />}
        <ImgMarginWrapper>
          <PimcoreImage image={secondaryImage} modifier="w-full rounded-lg" withAspectRatio />
        </ImgMarginWrapper>
      </MediaCell5>
    </Grid>
  );
};

export default ExampleHeaderSection;

const Grid = styled.div.attrs({
  className: "tablet:grid tablet:grid-cols-12 gap-4 px-5 mb-12 tablet:mb-36 mt-18 tablet:mt-0 z-20",
})`
  @media ${theme.mediaQueries.tablet} {
    grid-template-rows: repeat(2, auto);
  }
`;
const ParagraphWrapper = styled.div.attrs<ParagraphProps>(({ prParagraph }) => ({
  className: `tablet:pr-${prParagraph ? prParagraph : "12"}`,
}))<ParagraphProps>``;
const ImgMarginWrapper = styled.div.attrs({ className: "mb-8 tablet:mb-0" })``;

const MediaCell1 = styled.div.attrs<MediaCellProps>(({ marginTop }) => ({
  className: `tablet:col-start-8 tablet:col-span-5 tablet:row-start-1 tablet:row-span-1 mb-14 tablet:mb-8 ${
    marginTop ? marginTop : ""
  }`,
}))<MediaCellProps>``;
const MediaCell2 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-2 tablet:row-span-1 tablet:pr-6",
})``;
const MediaCell3 = styled.div.attrs({
  className: "tablet:col-start-1 tablet:col-span-6 tablet:row-start-1 tablet:row-span-3 ",
})``;
const MediaCell4 = styled.div.attrs({
  className: "tablet:col-start-8 tablet:col-span-4 tablet:row-start-3 tablet:row-span-3",
})``;
const MediaCell5 = styled.div.attrs<MediaCellProps>(({ bigger }) => ({
  className: `hidden tablet:block relative ${
    bigger ? "tablet:col-start-2 tablet:col-span-5" : "tablet:col-start-3 tablet:col-span-4"
  } tablet:row-start-4 tablet:row-span-2`,
}))<MediaCellProps>``;
